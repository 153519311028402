import OptionsBase from "./OptionsBase";
import { cloneDeep } from "lodash";

export default class SplineChart extends OptionsBase {

  _options;

  optionsSample = {
    title  : {},
    chart  : {
      height    : 350,
      animations: {
	enabled: false,
      },
    },
    stroke : {
      curve: "smooth",
    },
    xaxis  : {
      type      : "datetime",
      categories: [],
    },
    legend : {
      show: true,
    },
    tooltip: {
      x: {
	format: "dd/MM/yy",
      },
    },
  };

  /**
   *
   * @param title {string}
   */
  constructor(title) {
    super();
    this._options            = cloneDeep(this.optionsSample);
    this._options.title.text = title;
    this.setLocaleSettings();
  }

  /**
   *
   * @param val {Array<any>}
   */
  set xAxisLabels(val) {
    this._options.xaxis = this._generateXAxis(val);
  }

  /**
   *
   * @param xAxisLabels {Array<any>}
   * @private
   * @return {Object}
   */
  _generateXAxis(xAxisLabels) {
    return {
      type      : "datetime",
      categories: xAxisLabels,
    };

  }
}
