










































































































































import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import TableExportButton from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import ChartsDataConstructor from "@/includes/logic/chartsDataConstructor";

@Component({
  'components': {
    VueApexCharts,
    TableExportButton
  }
})
export default class InviteLinksStatChart extends ChartsDataConstructor {
  @Prop() statisticsData!: any;
}
