import StackedBarChart from "@/includes/logic/StackedBarChart";
import inviteLinksStatistics from "@/mixins/statistics/InviteLinks/inviteLinksStatistics";

export default {
    data() {
        return {};
    },
    methods: {
        accumulateParams(ctx, obj, from, to) {
            const resultObj = {
                options: {},
                series: [],
            };
            const seriesResult = [];
            const first_enter = [];
            const first_leave = [];

            if (obj.first_enter) {
                first_enter.push(obj.first_enter.data.map(d => d.data));
                seriesResult.push(this.sumArray(first_enter[0], ctx.$t("first_enter")));
            }
            if (obj.first_leave) {
                first_leave.push(obj.first_leave.data.map(d => d.data));
                seriesResult.push(this.sumArray(first_leave[0], ctx.$t("first_leave")));
            }

            const stackedBarChart = new StackedBarChart(ctx.$t('link_statistics_stacked_chart'));
            stackedBarChart.xAxisLabels = inviteLinksStatistics.methods.getTimeTicks(from, to);
            resultObj.series = seriesResult;
            resultObj.options = stackedBarChart.options;

            return resultObj;
        },

        sumArray(array, name) {
            const newArray = [];
            array.forEach(sub => {
                sub.forEach((num, index) => {
                    if (newArray[index]) {
                        newArray[index] += num;
                    } else {
                        newArray[index] = num;
                    }
                });
            });
            return {
                name: name,
                data: newArray,
            };
        },

    },
};
