import inviteLinksStatistics from "@/mixins/statistics/InviteLinks/inviteLinksStatistics";
import SplineChart           from "@/includes/logic/SplineChart";

// maxVal <= 3600 	-> maxVal/60 		** minutes
// maxVal <= 360000 	-> maxVal/3600 		** hours
// maxVal <= 5200000 	-> maxVal/86400 	** days
// maxVal <= 63100000 	-> maxVal/2628000 	** months
// maxVal => 63100000 	-> maxVal/31540000 	** years

export default {
  methods: {
    setOptionsAndSeries(ctx, data, length, title, from, to) {
      const resultObj = {};
      let unit        = "";
      let max         = 0;
      max             = this.mergeArrayAndGetMax(data);

      if(data.length === length) {
	unit = this.getUnit(max);
	data.forEach(d => {
	  d.data = this.updateDataValues(d.data, unit);
	});
      }

      const splineChart       = new SplineChart(`${ ctx.$t(`${ title }`) }, ${ ctx.$t(`${ unit }`) }`);
      splineChart.xAxisLabels = inviteLinksStatistics.methods.getTimeTicks(from, to);
      resultObj.options       = splineChart.options;
      return resultObj.options;
    },

    updateDataValues(data, unit) {
      if(unit === "seconds") {
	return data.map(val => val);
      }
      if(unit === "minutes") {
	return data.map(val => val / 60);
      }
      if(unit === "hours") {
	return data.map(val => Math.round(val / 3600));
      }
      if(unit === "days") {
	return data.map(val => Math.round(val / 86400));
      }
      if(unit === "months") {
	return data.map(val => Math.round(val / 2628000));
      } else {
	return data.map(val => Math.round(val / 31540000));
      }
    },
    getUnit(max) {
      if(max <= 60) {
	return "seconds";
      }
      if(max <= 3600) {
	return "minutes";
      }
      if(max <= 360000) {
	return "hours";
      }
      if(max <= 5200000) {
	return "days";
      }
      if(max <= 63100000) {
	return "months";
      } else {
	return "years";
      }
    },
    countMaxValue(data) {
      let t = 0;
      data.map(i => { if(t < i) t = i; });
      return t;
    },
    mergeArrayAndGetMax(data) {
      let mergedArray = [];
      data.forEach(d => mergedArray.push(...d.data));
      return this.countMaxValue(mergedArray);
    },
  },
};
