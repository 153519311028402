import moment from "moment";
import { cloneDeep } from "lodash";
import accumulator from "@/mixins/statistics/InviteLinks/accumulator/accumulator";
import activePeriod from "@/mixins/statistics/InviteLinks/active-period/active-period";
import fixedValues from "@/mixins/statistics/InviteLinks/fixed-values/fixed-values";

export default {
    mixins: [ accumulator, activePeriod, fixedValues ],
    methods: {
        prepareStatData(ctx, data, isDayApiCall, fromDate, toDate) {
            const resObject = {};
            const timeTicks = [];
            const dataTicks = [];
            let from = "";
            let to = "";
            if (isDayApiCall) {
                from = moment().add(-7, "days").startOf("day");
                to = moment().toISOString();
            } else {
                from = moment(fromDate);
                to = moment(toDate);
            }

            while (!from.isAfter(to)) {
                timeTicks.push(from.format("YYYY-MM-DD").toLocaleLowerCase());
                dataTicks.push(0);
                from.add(1, "day");
            }

            data.map(item => {
                if (item.data) {
                    const first_enter = {
                        name: isDayApiCall ? ctx.$t("first_enter") : "first_enter",
                        data: cloneDeep(dataTicks),
                    };
                    const first_leave = {
                        name: isDayApiCall ? ctx.$t("first_leave") : "first_leave",
                        data: cloneDeep(dataTicks),
                    };
                    const active_period_all_enter = {
                        name: "active_period_all_enter",
                        data: cloneDeep(dataTicks),
                    };
                    const active_period_all_leave = {
                        name: "active_period_all_leave",
                        data: cloneDeep(dataTicks),
                    };
                    const active_period_all_kicked = {
                        name: "active_period_all_kicked",
                        data: cloneDeep(dataTicks),
                    };

                    item.items.map(item => {
                        let newItemTime = item.time.split(" ")[0];
                        if (timeTicks.includes(newItemTime)) {
                            const index = timeTicks.indexOf(newItemTime);
                            if (item.first_enter) {
                                first_enter.data[index] += item.first_enter;
                            }
                            if (item.first_leave) {
                                first_leave.data[index] += item.first_leave;
                            }
                            if (item.active_period_all_enter) {
                                active_period_all_enter.data[index] = item.active_period_all_enter;
                            }
                            if (item.active_period_all_leave) {
                                active_period_all_leave.data[index] = item.active_period_all_leave;
                            }
                            if (item.active_period_all_kicked) {
                                active_period_all_kicked.data[index] = item.active_period_all_kicked;
                            }
                        }
                    });
                    resObject[item.data.link ? item.data.link : "link_statistics_no_link"] = {
                        title: item.data.link,
                        name: item.data.title ? item.data.title : ctx.$t("link_statistics_no_link"),
                        series: [ first_enter, first_leave, active_period_all_enter, active_period_all_leave, active_period_all_kicked ],
                    };
                }
            });

            return resObject;
        },

        getTimeTicks(fromDate, toDate) {
            const from = moment(fromDate);
            const to = moment(toDate);
            const timeTicks = [];
            const dataTicks = [];
            while (!from.isAfter(to)) {
                timeTicks.push(from.format("YYYY-MM-DD HH:mm:ss").toLocaleLowerCase());
                dataTicks.push(0);
                from.add(1, "day");
            }
            return timeTicks;
        },

        setObjectForChart(ctx) {
            return new Promise(resolve => {
                const actualLinks = ctx.customIntervalLinks;
                const length = ctx.selected_links.length;
                const tags = ctx.selected_links;
                const from = ctx.date.from;
                const to = ctx.date.to;

                const values = Object.values(actualLinks);
                let resObj = {
                    first_enter: {
                        data: [],
                        options: {},
                    },
                    first_leave: {
                        data: [],
                        options: {},
                    },
                    active_period_all_enter: {
                        data: [],
                        options: {},
                    },
                    active_period_all_leave: {
                        data: [],
                        options: {},
                    },
                    active_period_all_kicked: {
                        data: [],
                        options: {},
                    },
                    accumulator: [],
                };

                const itemsToChart = values.filter(v => v.title ? tags.includes(v.title) || !v.name : tags.includes(v.title));

                itemsToChart.forEach((item) => {
                    item.series.forEach((metric) => {
                            if (metric.name === "first_enter") {
                                resObj.first_enter.data.push({
                                    name: item.name ? item.name : item.title,
                                    data: metric.data,
                                });
                                resObj.first_enter.options = fixedValues.methods.setOptionsAndSeries(ctx, metric.data,
                                    metric.name, from, to);
                            }
                            if (metric.name === "first_leave") {
                                resObj.first_leave.data.push({
                                    name: item.name ? item.name : item.title,
                                    data: metric.data,
                                });
                                resObj.first_leave.options = fixedValues.methods.setOptionsAndSeries(ctx, metric.data,
                                    metric.name, from, to);
                            }
                            if (metric.name === "active_period_all_enter") {
                                resObj.active_period_all_enter.data.push({
                                    name: item.name ? item.name : item.title,
                                    data:
                                    metric.data,
                                });
                                resObj.active_period_all_enter.options = fixedValues.methods.setOptionsAndSeries(ctx, metric.data, metric.name, from, to);
                            }
                            if (metric.name === "active_period_all_leave") {
                                resObj.active_period_all_leave.data.push({
                                    name: item.name ? item.name : item.title,
                                    data: metric.data,
                                });
                                resObj.active_period_all_leave.options = activePeriod.methods.setOptionsAndSeries(ctx, resObj.active_period_all_leave.data, length, metric.name, from, to);
                            }
                            if (metric.name === "active_period_all_kicked") {
                                resObj.active_period_all_kicked.data.push({
                                    name: item.name ? item.name : item.title,
                                    data: metric.data,
                                });
                                resObj.active_period_all_kicked.options = activePeriod.methods.setOptionsAndSeries(ctx, resObj.active_period_all_kicked.data, length, metric.name, from, to);
                            }
                        },
                    );
                });

                resObj.accumulator.push(accumulator.methods.accumulateParams(ctx, resObj, from, to));
                resolve(resObj);
            });

        },
    },
};

