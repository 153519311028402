



































































import { InviteLinksService } from '@/includes/services/InviteLinksService'
import { InviteLinkChart } from '@/components/InviteLinks/types'
import InviteLinksStatChart from '@/components/InviteLinks/InviteLinksStatChart.vue'
import inviteLinksStatistics from '@/mixins/statistics/InviteLinks/inviteLinksStatistics'
import statisticsTranslationsMixin from '@/mixins/statistics/statisticsTranslationsMixin'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import SaveConfigPanel from '@/components/SaveConfigPanel.vue'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification } from '@/includes/services/NotificationService'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Mixins, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import { mapGetters } from 'vuex'

@Component({
  data() {
    return {
      options: [],
    }
  },
  components: {
    PageTitle,
    InviteLinksStatChart,
    VueApexCharts,
    MobileLandscapeTriggerLayout,
    flatPickr,
    ConfigField,
    SaveConfigPanel,
    EmptyData
  },
  mixins: [ statisticsTranslationsMixin ],
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class LinkStatistics extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  getBoardById!:GetBoardByIdType

  isDataSet = false

  linksToShow: any = null

  //date/time
  date = {
    from: moment().add(-30, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  }

  //chips for selected links
  selected_links: string[] = []

  isLoading = false

  @Watch('selected_links')
  onTagsChange(): void {
    this.isLoading = true
    setTimeout(() => {
      this.getStatisticsBySelectedLinks()
      this.isLoading = false
    }, 5)
  }

  //links from api with custom interval
  customIntervalLinks: any

  getOptions(): Promise<any> {
    return new Promise(resolve => resolve(this.$data.options))
  }

  //get links stat from api
  getInviteLinksStat(isFirstCall?: boolean) {
    this.isDataSet = false
    let selectedLink = ''
    if (isFirstCall) selectedLink = this.$route.params.linkCode
    this.isLoading = true
    return new Promise((resolve, reject) => {
      InviteLinksService.getInviteLinksStat('tg', {
        board_key: this.getBoardById(this.$route.params.id)!.board,
        target_id: Number.parseInt(this.$route.params.channelId),
        interval: {
          interval_type: 'Full',
          from: this.date.from,
          to: this.date.to
        },
      }).then((data) => {
        this.customIntervalLinks = inviteLinksStatistics.methods.prepareStatData(this, data.links, false, this.date.from, this.date.to)
        if (selectedLink && this.customIntervalLinks[selectedLink]) {
          this.selected_links.push(this.customIntervalLinks[selectedLink].title)
          this.linksToShow = this.customIntervalLinks[selectedLink]
        }
        if (this.selected_links.length) {
          this.getStatisticsBySelectedLinks()
        }
        resolve(this.customIntervalLinks)
      })
        .catch(err => {
          errorNotification(err)
          reject(err)
        })
        .finally(() => this.isLoading = false)
    })
  }

  //push links to select when component is mounted
  pushLinksToSelect(links: InviteLinkChart[]) {
    Object.values(links).forEach((link: InviteLinkChart) => {
      this.$data.options.push({
        label: link.name,
        value: link.title,
      })
    })
  }

  //get statistics for link
  getStatisticsBySelectedLinks() {
    if (!this.selected_links.length) {
      this.linksToShow = null
      return
    }

    inviteLinksStatistics.methods.setObjectForChart(this)
      .then(res => {
        this.linksToShow = res
      })
      .finally(() => this.isDataSet = true)
  }

  get isRightDate() {
    return (moment(this.date.from).isBefore(this.date.to, 'day')) && (!moment(this.date.to).isAfter(moment(), 'day'))
  }

  //lifecycle
  async mounted(): Promise<void> {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
    await this.$store.dispatch('getStatisticsLocales')

    this.getInviteLinksStat(true)
      .then((data: any) => {
        this.pushLinksToSelect(data)
      })
  }
}
