import OptionsBase from "./OptionsBase";
import { cloneDeep } from "lodash";

export default class StackedBarChart extends OptionsBase {

  _options;
  optionsSample = {
    title     : {},
    chart      : {
      type   : "bar",
      height : 350,
      stacked: true,
      toolbar: {
	show: true,
      },
      zoom   : {
	enabled: true,
      },
      animations:{
        enabled:false
      }
    },
    plotOptions: {
      bar: {
	horizontal  : false,
	borderRadius: 10,
      },
    },
    xaxis      : {
      type      : "datetime",
      categories: []
      ,
      legend    : {
	position: "bottom",
	offsetY : 0,
      },
      fill      : {
	opacity: 1,
      },
    },
  };

  /**
   *
   * @param title {string}
   */
  constructor(title) {
    super();
    this._options            = cloneDeep(this.optionsSample);
    this._options.title.text = title;
    this.setLocaleSettings();
  }

  /**
   *
   * @param val {Array<any>}
   */
  set xAxisLabels(val) {
    this._options.xaxis = this._generateXAxis(val);
  }

  /**
   *
   * @param xAxisLabels {Array<any>}
   * @private
   * @return {Object}
   */
  _generateXAxis(xAxisLabels) {
    return {
      type      : "datetime",
      categories: xAxisLabels,
    };
  }

}
