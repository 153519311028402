import inviteLinksStatistics from "@/mixins/statistics/InviteLinks/inviteLinksStatistics";
import SplineChart           from "@/includes/logic/SplineChart";

export default {
  methods: {
    setOptionsAndSeries(ctx, data, title, from, to) {
      const resultObj         = {};
      const splineChart       = new SplineChart(ctx.$t(`${ title }`));
      splineChart.xAxisLabels = inviteLinksStatistics.methods.getTimeTicks(from, to);

      resultObj.options       = splineChart.options;
      return resultObj.options;
    },
  },
};
