export default {
	computed: {
		$t_bot_not_admin_overlay_text() {
			return this.$te('bot_not_admin_overlay_text') ? this.$t('bot_not_admin_overlay_text') : "";
		},
		$t_date_picker_from() {
			return this.$te('date_picker_from') ? this.$t('date_picker_from') : "";
		},
		$t_date_picker_to() {
			return this.$te('date_picker_to') ? this.$t('date_picker_to') : "";
		},
	}
}
